.index-page {
  padding-bottom: 10vw;

}

@include respond-up('large') {
  .index-page {
  }
}

@include respond('medium') {
  .index-page {
  }
}

@include respond-down('small') {
  .index-page {
  }
}

