.map {
  position: relative;
  align-items: center;
  z-index: 20;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text-part {
    display: flex;
    align-items: center;
  }

  &__map {
    opacity: 1;
    transition: opacity;
    transition-duration: .4s;
    transition-timing-function: var(--teaser-transition-func);

    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {
        animation-delay: calc(#{$i} * 0.05s);
      }
    }
  }
}

@include respond-up('large') {
  .map {
    margin-top: 170px;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      top: calc(0px - 183 / 760 * var(--grid-column3));;
      height: calc(133 / 760 * var(--grid-column3));
      left: calc(var(--grid-column1) + var(--grid-gap));
      background: #DBC4B2;
    }

    &_first {
      margin-top: 760px;

      &:before {
        top: calc(0px - 163 / 760 * var(--grid-column3));;
      }
    }

    &__text {
      grid-column: 1/3;
      height: calc(var(--grid-column2) * 525 / 492);
    }

    &__map {
      grid-column: 3/7;
      height: calc(var(--grid-column4) * 636 / 1019);
    }
  }
}

@include respond('medium') {
  .map {
    margin-top: calc(100vw * 200 / 1600);

    &_first {
      margin-top: calc(100vw * 720 / 1600);
    }
  }
}

@include respond-down('medium') {
  .map {
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      top: calc(0px - 103 / 760 * var(--grid-column3));;
      height: calc(53 / 760 * var(--grid-column3));
      background: #DBC4B2;
      left: 50%;
    }

    &__text {
      grid-column: 1/5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-content: center;

      > * {
        margin-top: 10px;
      }
    }

    &__text-part {
      justify-content: center;
    }

    &__map {
      grid-column: 1/5;
      height: calc(var(--grid-column4) * 636 / 1019);
    }
  }
}

@include respond-down('small') {
  .map {
    margin-top: calc(100vw * 130 / 767);

    &_first {
      margin-top: calc(100vw * 100 / 767);
    }
  }
}