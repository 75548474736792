:root {
  --default-input-height: 37px;
  --errors-color: var(--alert-color, #b50000)
}

$inputs: 'input[type="text"], input[type="search"], input[type="password"]';

form {

  div {
    width: var(--grid-column2);
  }

  #{$inputs}, textarea, select {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset;
      -webkit-text-fill-color: var(--primary-color);
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-transition-function);
    }
  }

  label {
    color: var(--primary-color);
    margin-bottom: 7px;
    margin-top: 22px;
    display: block;
    font-weight: 400;
    line-height: 110%;
  }

  ul.errors {
    margin-top: 10px;
    list-style: none;
    color: var(--errors-color);
    font-size: 13px;
    width: var(--grid-column2);

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    font-size: 14px;
    display: block;
    appearance: none;
    font-family: var(--font);
    background: var(--line-color);
    width: var(--grid-column2);
    padding-left: 12px;
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  .button, button {
    margin: 0;
    text-align: center;
    border: none;
    background: var(--line-color);
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 500;
    line-height: 110%;
    margin-top: 14px;
    padding: 14px;
    width: var(--grid-column2);
    transition: background-color;
    transition-duration: .35s;
    transition-timing-function: var(--teaser-transition-func);

    &:hover {
      background-color: #DEB88E;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  form {
    #{$inputs}, textarea, select, label {
      font-size: 30px;
      padding-bottom: 20px;
      height: 50px;
    }
  }
}

@include respond('medium') {
  form {
    #{$inputs}, textarea, select, label {
      font-size: 25px;
      padding-bottom: 16px;
    }
  }
}

@include respond-down('small') {
  form {
    #{$inputs}, textarea, select, label {
      font-size: 20px;
      padding-bottom: 12px;
    }
  }
}