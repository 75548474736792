.form-block {
  &__button {
    margin-top: 4vw;
    z-index: 20;
  }
}

@include respond-up('large') {
  .form-block {
    padding-bottom: calc(100vw / 1728 * 150);;

    &__text {
      grid-column: 2/6;
      margin-top: calc(100vw / 1728 * 150);
    }

    &__button {
      grid-column: 3/5;
      justify-self: center;
    }

    &__form {
      grid-column: 1/7;
    }
  }
}

@include respond('medium') {
  .form-block {
    &__button {
      grid-column: 2/4;
      justify-self: center;
    }
  }
}

@include respond-down('medium') {
  .form-block {
    padding-bottom: calc(100vw / 864 * 50);;

    &__text {
      grid-column: 1/5;
      margin-top: calc(100vw / 864 * 50);
    }

    &__form {
      grid-column: 1/5;
    }
  }
}

@include respond-down('small') {
  .form-block {

    &__button {
      grid-column: 1/5;
    }
  }
}


