.decor {
  position: absolute;
  height: 50vh;
  //opacity: 0.2;
  z-index: 10;
  display: flex;

  //svg {
  //  height: 100%;
  //}
}