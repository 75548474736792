%text-medium {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: var(--primary-color);
  transition: opacity, transform;
  transition-duration: .4s;
  transition-timing-function: var(--teaser-transition-func);
}

@include respond-up("large") {
  %text-medium {
    --font-size: clamp(0px, calc((44 / 1728) * 100vw), 50px);
  }
}

@include respond("medium") {
  %text-medium {
    --font-size: calc((27 / 834) * 100vw);
  }
}

@include respond-down("small") {
  %text-medium {
    --font-size: 20px;
  }
}