.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100); //true-vh
  background: var(--background-color);

  &__content {
    overflow: hidden;
  }
}