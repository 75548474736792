.pictures {
  position: relative;

  &__picture {
    display: flex;
    transition: transform, opacity;
    transition-delay: .2s;
    transition-duration: .8s;
    transition-timing-function: var(--teaser-transition-func);
  }

  &__image-wrap,
  &__text {
    z-index: 20;
    position: relative;
    margin-top: 4vw;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-wrap {
    opacity: 1;
    transition: opacity;
    transition-duration: .4s;
    transition-timing-function: var(--teaser-transition-func);

    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {
        animation-delay: calc(#{$i} * 0.1s);
      }
    }

    &:nth-child(even) {
      transform: translateY(-45px);
    }
  }

  &__parallax-container {
    height: 100%;
    overflow: hidden;
  }
}

@include respond-up('large') {
  .pictures {
    &__image-wrap {
      grid-column: span 3;
    }

    &__decor {
      right: 5vw;
      bottom: 15vw;
    }

    &__image-wrap,
    &__parallax-container {
      height: calc(865 / 760 * var(--grid-column3));
    }

    &__picture {
      height: calc(915 / 760 * var(--grid-column3));
      width: var(--grid-column3);
    }
  }
}

@include respond('medium') {
  .pictures {
    margin-top: 7vw;


    &__decor {
      bottom: 10vh;
      right: 20vw;
    }

    &__image-wrap {
      grid-column: span 1;
    }
  }
}

@include respond-down('small') {
  .pictures {
    &__text {
      grid-column: span 4;
      text-align: center;
    }
  }
}

@include respond-down('small') {
  .pictures {
    margin-top: 13vw;


    &__decor {
      bottom: 260%;
      right: 15vw;
    }

    &__image-wrap {
      grid-column: span 1;
    }
  }
}
