.button {
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  border: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 100px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: 600;
  padding: 14px 30px;
  background: var(--line-color);

  //position: relative;
  //
  //&:before {
  //  position: absolute;
  //  content: '';
  //  height: 1px;
  //  width: 100%;
  //  background: var(--primary-color);
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //}
}

@include respond-up('large') {

}