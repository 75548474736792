.need-animation {
  .h1,
  .text__medium,
  .text__huge,
  .text__main {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }

  .map__map,
  .pictures__image-wrap {
    opacity: 0;
  }

  .hero {
    &:before {
      transform: translate3d(0, -100%, 0);
    }

    &__picture {
      opacity: 0;
    }
  }
}

.animate-out {
  .h1,
  .text__main {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  .hero {
    &:before {
      transform: translate3d(0, 0, 0);
    }

    &__picture {
      opacity: 1;
    }
  }
}

@include respond-down('small') {
  .need-animation {
    .hero {
    }
  }
}