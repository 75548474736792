%text-main {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  color: var(--primary-color);
  line-height: 170%;
  transition: opacity, transform;
  transition-duration: .4s;
  transition-timing-function: var(--teaser-transition-func);
}

@include respond-up("large") {
  %text-main {
    --font-size: clamp(27px, calc((27 / 1728) * 100vw), 32px);
  }
}

@include respond("medium") {
  %text-main {
    --font-size: clamp(14px, calc((22 / 834) * 100vw), 27px);
  }
}

@include respond-down("small") {
  %text-main {
    --font-size: 14px;
  }
}