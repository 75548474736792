%text-huge {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  color: var(--primary-color);
  line-height: 170%;
  transition: opacity, transform;
  transition-duration: .4s;
  transition-timing-function: var(--teaser-transition-func);
}

@include respond-up("large") {
  %text-huge {
    --font-size: clamp(0px, calc((60 / 1728) * 100vw), 80px);
  }
}

@include respond("medium") {
  %text-huge {
    --font-size: calc((27 / 834) * 100vw);
  }
}

@include respond-down("small") {
  %text-huge {
    --font-size: 23px;
  }
}