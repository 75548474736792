.hero {
  position: relative;

  &:before {
    transition: transform;
    transition-duration: .4s;
    transition-timing-function: var(--teaser-transition-func);
  }

  &__parallax-container {
    height: 100%;
    overflow: hidden;
  }

  &__picture {
    display: flex;
    transition: transform, opacity;
    transition-delay: .2s;
    transition-duration: .8s;
    transition-timing-function: var(--teaser-transition-func);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-wrap,
  &__text {
    z-index: 20;
    position: relative;
  }
}

@include respond-up('large') {
  .hero {
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      top: 0;
      height: calc(633 / 760 * var(--grid-column3));
      left: calc(var(--grid-column1) + var(--grid-gap));
      background: var(--line-color);
    }

    &__decor {
      top: 18vw;
      right: -50%;
    }

    &__image-wrap {
      grid-column: 4/7;
    }

    &__image-wrap,
    &__parallax-container {
      height: calc(865 / 760 * var(--grid-column3));
    }

    &__picture {
      height: calc(915 / 760 * var(--grid-column3));
      width: var(--grid-column3);
    }

    &__text {
      left: 0;
      top: calc(733 / 760 * var(--grid-column3));
      width: var(--grid-column5);

      &_one {
      }

      &_two {
        left: 60px;
        top: -140px;
      }

      &_three {
        left: calc(var(--grid-column2) - 0.5 * var(--grid-column1));
        top: calc(100vw * 85 / 1728);
      }

      &_four {
        left: var(--grid-column1);
        top: calc(100vw * 60 / 1728);
      }

      &_five {
        left: calc(var(--grid-column1) + var(--grid-gap));
        top: calc(2 * var(--grid-column1));
      }
    }
  }
}

@include respond-up('medium') {
  .hero {
    &__parallax-container {
      position: absolute;
      bottom: 0;
    }

    &__text-wrap {
      position: relative;

      > * {
        position: absolute;
      }
    }

    &__text {
      position: absolute;
    }
  }
}

@include respond('medium') {
  .hero {
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      top: 0;
      height: calc(300 / 533 * var(--grid-column3));
      left: calc(var(--grid-column1) + var(--grid-gap));
      background: var(--line-color);
    }

    &__image-wrap {
      grid-column: 3/5;
    }

    &__parallax-container {
    }

    &__image-wrap,
    &__parallax-container {
      height: calc(470 / 350 * var(--grid-column2));
    }

    &__picture {
      height: calc(470 / 350 * var(--grid-column2));
      width: var(--grid-column2);
    }

    &__text {
      left: 0;
      top: calc(var(--grid-column2));
      width: var(--grid-column4);

      &_one {
        top: -30px;
      }

      &_two {
        left: 30px;
        top: calc(0px - 100vw * 50 / 768);
      }

      &_three {
        left: calc(var(--grid-column1) + 2.5 * var(--grid-gap));
        top: calc(100vw * 80 / 768);
      }

      &_four {
        left: var(--grid-column1);
        top: calc(100vw * 60 / 768);
      }

      &_five {
        left: 0;
        top: calc(1.5 * var(--grid-column1));
      }
    }
  }
}

@include respond-down('small') {
  .hero {
    &__text,
    &__image-wrap {
      grid-column: 1/5;
    }

    &__image-wrap,
    &__parallax-container {
      height: calc(400 / 330 * var(--grid-column4));
    }

    &__picture {
      height: calc(430 / 330 * var(--grid-column4));
      width: var(--grid-column4);
    }

    &__text {
      text-align: center;
      //left: 0;
      //top: calc(var(--grid-column2));
      //width: var(--grid-column4);

      &_one {
        display: inline;
      }

      &_two {
        display: inline;
        padding-left: 4px;
        //left: 30px;
        //top: -70px;
      }

      &_three {
        display: inline;
        //left: calc(var(--grid-column1) + 2.5 * var(--grid-gap));
        //top: calc(100vw * 85 / 768);
      }

      &_four {
        display: inline;
        //left: var(--grid-column1);
        //top: calc(100vw * 60 / 768);
        padding-left: 4px;
      }

      &_five {
        margin-top: calc((25 / 375) * 100vw);
        //left: 0;
        //top: calc(1.5 * var(--grid-column1));
      }
    }
  }
}
